/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import moment from 'moment-timezone';
import { cloneDeep, uniq, isEqual, uniqBy, sortBy, groupBy, minBy, maxBy } from 'lodash';
import { Button, Typography, Divider, Empty, Row, Col, Select, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import shiftsEmpty from '../../assets/images/shifts_empty.png';
import {
  employmentStatuses,
  attendanceStatuses,
  DATE_KEY_FORMAT,
  WEEKLY_DATE_RANGE,
  derivedWorkingStatus,
  MONTHLY_DATE_RANGE,
  localStorageKeys,
  employmentTypes,
} from '../../constants';
import shiftApi from '../../services/shiftApi';
import employmentApi from '../../services/employmentApi';
import scheduleApi from '../../services/scheduleApi';
import fetchAll from '../../utilities/apiUtils';
import datetimeUtils from '../../utilities/datetimeUtils';
import employmentUtils from '../../utilities/employmentUtils';
import attendanceUtils from '../../utilities/attendanceUtils';
import { updateUrlWithParams, retrieveParamsFromUrl } from '../../utilities/urlUtils';
import ShiftsSummaryCard from './components/ShiftsSummaryCard';
import AddScheduleFormModal from './components/AddScheduleFormModal';
import AssignmentListView from './AssignmentListView';
import ScheduleListView from './ScheduleListView';
import StickyLegendBar from './components/StickyLegendBar';
import WeekMonthSelectGroup from './components/WeekMonthSelectGroup';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const LOCATION_KEY = 'locationId';
const POSITION_KEY = 'positionId';
const COPY_SHIFTS = 'copyShifts';
const DEFAULT_DATE_RANGE = WEEKLY_DATE_RANGE;
const DEFAULT_TIMEZONE = moment.tz.guess();

class ShiftPage extends React.Component {
  state = {
    scheduleLoading: false,
    assignmentsLoading: false,
    statisticsLoading: false,
    // selectedDateStart is a moment object with local timezone info, NOT user's browser timezone
    selectedDateStart: undefined,
    // selectedDayDates are derived from selectedWeekStart, each date is a moment object with local timezone info
    // Used to derive schedules and shifts table column and match corresponding shift and attendances of the day
    selectedDayDates: [],
    shifts: [],
    originalShifts: [],
    employments: [],
    absencesCount: undefined,
    unconfirmedWorkerList: [],
    unconfirmedAssignmentCount: undefined,
    confirmedAssignmentCount: undefined,
    addScheduleModalVisible: false,
    locationId: undefined,
    positionId: undefined,
    timezone: undefined,
    allSchedules: [],
    autoAssignLoading: false,
    selectedDateRange: DEFAULT_DATE_RANGE,
    // selectedDateRange: MONTHLY_DATE_RANGE,
  };

  async componentDidMount() {
    const { clientId, locations } = this.props;
    const { locationId, positionId } = await this.getFilterValues();
    let selectedLocation = locations[0];
    // fetch shifts only when all params are present
    if (locationId && positionId) {
      selectedLocation = locations.find(location => location.id === locationId);
      const timezone = selectedLocation?.address?.area?.city?.timezone || DEFAULT_TIMEZONE;
      await this.updateStateFromParams(timezone);
      const dateStart = this.initializeDayDates(timezone);
      await this.fetchSchedules(clientId, dateStart);
      try {
        this.setState(
          {
            locationId,
            positionId,
          },
          () => this.handleRefresh(),
        );
      } catch {
        message.error('Sorry! Location or position not found, please contact support');
      }
    } else {
      const timezone = selectedLocation?.address?.area?.city?.timezone || DEFAULT_TIMEZONE;
      await this.updateStateFromParams(timezone);
      const dateStart = this.initializeDayDates(timezone);
      await this.fetchSchedules(clientId, dateStart);
    }
  }

  getDayDates = (selectedDateStart, dateRangeType = null) => {
    const { selectedDateRange } = this.state;
    const dateRange = dateRangeType || selectedDateRange;
    return dateRange === WEEKLY_DATE_RANGE
      ? [...Array(7).keys()].map(day => selectedDateStart.clone().add(day, 'days'))
      : [...Array(moment(selectedDateStart).daysInMonth()).keys()].map(day =>
          selectedDateStart.clone().add(day, 'days'),
        );
  };

  getFilterValues = async () => {
    let { locationId, positionId } = this.state;
    // If no URL param, get local storage values if any
    if (!(locationId || positionId)) {
      if (localStorage.getItem(localStorageKeys.SHIFT_FILTERS)) {
        const shiftFilters = JSON.parse(localStorage.getItem(localStorageKeys.SHIFT_FILTERS));
        locationId = shiftFilters[LOCATION_KEY];
        positionId = shiftFilters[POSITION_KEY];
      }
    }
    return { locationId, positionId };
  };

  initializeDayDates = timezone => {
    const { selectedDateStart, selectedDateRange } = this.state;
    let dateStart = null;
    if (selectedDateRange === MONTHLY_DATE_RANGE) {
      dateStart = selectedDateStart || datetimeUtils.getIsoMonthStart(moment(), timezone);
    } else if (selectedDateRange === WEEKLY_DATE_RANGE) {
      dateStart = selectedDateStart || datetimeUtils.getIsoWeekStart(moment(), timezone);
    }
    const dayDates = this.getDayDates(dateStart);
    this.setState({
      selectedDateStart: dateStart,
      selectedDayDates: dayDates,
      timezone,
    });
    return dateStart;
  };

  updateStateFromParams = async timezone => {
    const { location_id, position_id, selected_date_start, selected_date_range } = retrieveParamsFromUrl(
      this.props.location.search,
    );
    this.setState({
      locationId: location_id && Number(location_id),
      positionId: position_id && Number(position_id),
      selectedDateStart:
        selected_date_start &&
        moment(selected_date_start, 'DD-MM-YYYY')
          // setting true keeps local time when converting timezones
          .tz(timezone, true)
          .startOf('isoWeek'),
      selectedDateRange: selected_date_range || DEFAULT_DATE_RANGE,
    });
  };

  updateParamsFromState = () => {
    const { locationId, positionId, selectedDateStart, selectedDateRange } = this.state;
    updateUrlWithParams(
      {
        location_id: locationId || undefined,
        position_id: positionId || undefined,
        selected_date_start: selectedDateStart.format('DD-MM-YYYY') || undefined,
        selected_date_range: selectedDateRange,
      },
      this.props.history,
    );
  };

  handlePositionChange = async () => {
    const { locationId, positionId } = this.state;
    const { locations } = this.props;

    if (!locationId || !positionId) return;

    localStorage.setItem(localStorageKeys.SHIFT_FILTERS, JSON.stringify({ locationId, positionId }));

    this.setState({ scheduleLoading: true, assignmentsLoading: true, statisticsLoading: true });

    const selectedLocation = locations.find(location => location.id === locationId);
    const selectedDateStart = this.initializeDayDates(
      selectedLocation?.address?.area?.city?.timezone || DEFAULT_TIMEZONE,
    );

    const shifts = await this.fetchShifts(selectedDateStart);
    await this.fetchEmployments();
    this.getStatistics(shifts);
    this.setState({ scheduleLoading: false, assignmentsLoading: false, statisticsLoading: false });

    // To prevent navigate away warning prompt from old publish count, update URL after new shifts are set
    this.updateParamsFromState();
  };

  handleRefresh = async () => {
    this.updateParamsFromState();
    const [shifts] = await Promise.all([this.fetchShifts(this.state.selectedDateStart), this.fetchEmployments()]);
    this.getStatistics(shifts);
  };

  handleDateSelect = async (isoDate, publishCount) => {
    const shouldNavigateAway = this.shouldNavigateAway(publishCount);
    if (shouldNavigateAway) {
      const { timezone, selectedDateRange } = this.state;
      const selectedDateStart =
        selectedDateRange === WEEKLY_DATE_RANGE
          ? datetimeUtils.getIsoWeekStart(isoDate, timezone)
          : datetimeUtils.getIsoMonthStart(isoDate, timezone);
      this.updateSelectDateState(selectedDateStart);
      await this.fetchSchedules(this.props.clientId, selectedDateStart);
      const shifts = await this.fetchShifts(selectedDateStart);
      this.getStatistics(shifts);
    }
  };

  fetchEmployments = async () => {
    this.setState({ assignmentsLoading: true, statisticsLoading: true });
    const { locationId, positionId } = this.state;

    const params = {
      status: employmentStatuses.ACTIVE,
      location: locationId,
      position: positionId,
      lean: true, // fetch only needed employment data
      exclude_type: employmentTypes.GIG.value,
    };
    const employments = sortBy(await fetchAll(employmentApi.list, params), 'partner.first_name');
    this.setState({ employments, assignmentsLoading: false, statisticsLoading: false });
  };

  fetchShifts = async selectedDateStart => {
    this.setState({ scheduleLoading: true });
    const { locationId, positionId, selectedDateRange } = this.state;
    const startDate = selectedDateStart;
    const endDate = selectedDateStart.clone().endOf(`${selectedDateRange === WEEKLY_DATE_RANGE ? 'isoWeek' : 'month'}`);

    const params = {
      start_time_after: startDate.toISOString(),
      start_time_before: endDate.toISOString(),
      location: locationId,
      position: positionId,
      exclude_staff_request__employment_type: employmentTypes.GIG.value,
    };
    const shifts = await fetchAll(shiftApi.fetchShifts, params);
    this.setState({
      shifts,
      originalShifts: shifts,
      scheduleLoading: false,
    });
    return shifts;
  };

  handleAutoAssign = async (maxWorkDays = null) => {
    const { originalShifts, selectedDayDates, timezone, locationId, shifts } = this.state;
    const { clientId } = this.props;
    // special shifts with recommended_attendances
    this.setState({ autoAssignLoading: true });
    // Get list of unique position ids, loop through them and call recommended-shifts api
    const uniquePositionIds = [...new Set(originalShifts.map(shift => shift.position.id))];
    const requests = uniquePositionIds.map(positionId => {
      return shiftApi.getRecommendedShifts({
        shift_id: originalShifts.map(shift => shift.id).join(','),
        start_date: moment(minBy(selectedDayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        end_date: moment(maxBy(selectedDayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        max_work_days_per_worker: maxWorkDays,
        location: locationId,
        client: clientId,
        position: positionId,
      });
    });
    try {
      const responses = await Promise.all(requests);
      const futureAssignableShifts = responses.flat();
      this.setState({ autoAssignLoading: false });
      /*
        Update for future shifts only
        Call handleAssignmentUpdate, with special updates payload for parent -
        [{targetShiftId, date, workerId, targetRoleId}]
      */
      const updates = futureAssignableShifts.flatMap(shift => {
        return shift.recommended_attendances.map(att => ({
          targetShiftId: att.shift_id,
          date: shift.start_time,
          workerId: att.partner_id,
          targetRoleId: att.role_id,
          scheduleId: shifts.find(shiftValue => shiftValue.id === att.shift_id)?.schedule.id,
        }));
      });
      this.handleAssignmentUpdate(updates);
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ autoAssignLoading: false });
      throw error; // throw up to caller
    }
  };

  updateSelectDateState = selectedDateStart => {
    const dayDates = this.getDayDates(selectedDateStart);
    this.setState(
      {
        selectedDateStart: moment(selectedDateStart, 'DD-MM-YYYY'),
        selectedDayDates: dayDates,
      },
      this.updateParamsFromState,
    );
    return dayDates;
  };

  // Inputs structure: [{targetShiftId, date, workerId, source }]
  // source is optional and used only by copy shift function
  handleAssignmentUpdate = updates => {
    const { shifts, originalShifts } = this.state;
    const draftShifts = cloneDeep(shifts);
    updates.forEach(update => {
      const { targetShiftId, date, workerId, source } = update;
      const targetShiftIndex = draftShifts.findIndex(shiftItem => shiftItem.id === targetShiftId);
      // Unassignment logic:
      // Update same shift existing attendances to unassigned
      draftShifts.forEach(shift => {
        if (moment(date).isSame(shift.start_time, 'day')) {
          const attendanceIndex = shift.attendances.findIndex(
            attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
          );
          if (attendanceIndex >= 0) {
            if (shift.attendances[attendanceIndex].id) {
              // eslint-disable-next-line no-param-reassign
              shift.attendances[attendanceIndex] = {
                ...shift.attendances[attendanceIndex],
                status: attendanceStatuses.UNASSIGNED,
                confirmed: false,
                change_reason: undefined,
                unpublished: true,
              };
            } else shift.attendances.splice(attendanceIndex, 1);
          }
        }
      });

      // Update or insert attendance on the target shift
      if (targetShiftIndex >= 0) {
        const attendanceIndex = draftShifts[targetShiftIndex].attendances.findIndex(
          attendance => attendance.partner_id === workerId,
        );
        if (attendanceIndex >= 0) {
          const originalAttendance = originalShifts[targetShiftIndex].attendances.find(
            attendance =>
              attendance.id === draftShifts[targetShiftIndex].attendances[attendanceIndex].id &&
              attendance.status === attendanceStatuses.ASSIGNED,
          );
          // If original attendance exist and update source not copy shifts, revert back to original attendance state
          if (originalAttendance && !isEqual(source, COPY_SHIFTS)) {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
              ...originalAttendance,
              change_reason: null,
              employment_id: update?.employmentId,
            };
          } else {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
              ...draftShifts[targetShiftIndex].attendances[attendanceIndex],
              status: attendanceStatuses.ASSIGNED,
              confirmed: false,
              change_reason: null,
              employment_id: update?.employmentId,
            };
          }
        } else {
          draftShifts[targetShiftIndex].attendances.push({
            employment_id: update?.employmentId,
            shift_id: targetShiftId,
            partner_id: workerId,
            status: attendanceStatuses.ASSIGNED,
            confirmed: false,
          });
        }
      }
    });

    this.setState({
      shifts: draftShifts,
    });
  };

  // NOTE: there's a new changes in the backend that on each contract, it will
  // create a new employment for the worker, so we need to merge the assignments
  // for each worker on each contract
  mergeWorkerAssignments = workers => {
    // Find duplicate worker IDs
    const workerCountMap = workers.reduce((acc, worker) => {
      acc[worker.worker.id] = (acc[worker.worker.id] || 0) + 1;
      return acc;
    }, {});

    // Check if there are any duplicates
    const hasDuplicateWorkers = Object.values(workerCountMap).some(count => count > 1);
    if (!hasDuplicateWorkers) {
      return workers; // Return the original workers array if no duplicates
    }

    const workerMap = new Map();

    workers.forEach(worker => {
      const workerId = worker.worker.id;

      if (!workerMap.has(workerId)) {
        workerMap.set(workerId, { ...worker, assignments: [] });
      }

      // Filter working assignments and append employmentId
      const filteredAssignments = worker.assignments
        .filter(assignment => assignment.contractStatus === 'working')
        .map(assignment => ({
          ...assignment,
          employmentId: worker.employmentId,
        }));

      // Add assignments to the existing worker record
      workerMap.get(workerId).assignments.push(...filteredAssignments);
    });

    // Final adjustment: Choose the employmentId from the last "working" assignment
    const result = Array.from(workerMap.values()).map(worker => {
      if (worker.assignments.length > 0) {
        const lastAssignment = worker.assignments[worker.assignments.length - 1];
        // eslint-disable-next-line no-param-reassign
        worker.employmentId = lastAssignment.employmentId;
      }
      return worker;
    });

    return result;
  };

  /***************** Data Mapping Start ***********************/
  /* Generate data structure for assignment table
     Combine employment with shifts and originalShift, populate data structure like below
   assignmentData: [
      {
        id,
        worker: {},
        assignedCount: 0,
        assignments: [
          {date,schedule:{id, name},originalAttendance: {id, partner_id, shift_id,status, change_reason, confirmed},
          attendance: {id, partner_id, shift_id, status, change_reason, confirmed }, shifts: {id, schedule: {id, name}}},
          timesheetEntries: [{id, clock_in_time, status}]
        ]
      }
    ]*/
  generateAssignmentTableData = () => {
    let count = 0;
    const { selectedDayDates, employments, originalShifts, shifts, timezone } = this.state;
    const shiftsByDay = groupBy(shifts, shift => {
      return moment.tz(shift.start_time, timezone).format(DATE_KEY_FORMAT);
    });

    const originalShiftsByDay = groupBy(originalShifts, shift => {
      return moment.tz(shift.start_time, timezone).format(DATE_KEY_FORMAT);
    });

    let availableShiftsPerDay = {};
    for (const date in shiftsByDay) {
      const shiftsOnDate = shiftsByDay[date];
      const availableShifts = shiftsOnDate.map(shiftItem => {
        const startTime = moment.tz(shiftItem.start_time, timezone);
        const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shiftItem.duration);
        return {
          id: shiftItem.id,
          schedule: shiftItem.schedule,
          startTime,
          endTime,
        };
      });

      availableShiftsPerDay = { ...availableShiftsPerDay, [date]: availableShifts };
    }

    const assignments = employments.map(employment => {
      let assignedCount = 0;
      const dataItem = {
        id: employment.partner.id, // For table rowKey
        employmentId: employment.id,
        worker: employment.partner,
        assignments: selectedDayDates.map(currentDay => {
          const dateKey = currentDay.format(DATE_KEY_FORMAT);

          const currentDayShifts = shiftsByDay[dateKey] || [];
          const attendance = this.getAttendance(employment.partner.id, currentDayShifts);
          const shift = shifts.find(shiftItem => shiftItem.id === attendance?.shift_id);

          const originCurrentDayShifts = originalShiftsByDay[dateKey] || [];
          const originalAttendance = this.getAttendance(employment.partner.id, originCurrentDayShifts);

          // Statistics, write in this loop to avoid multiple loops when set as derived fields
          assignedCount += attendance?.status === attendanceStatuses.ASSIGNED ? 1 : 0;
          count += isEqual(attendance, originalAttendance) ? 0 : 1;

          return {
            date: currentDay,
            schedule: shift?.schedule,
            attendance,
            originalAttendance,
            shifts: availableShiftsPerDay[dateKey] || [],
            contractStatus: employmentUtils.getDerivedWorkingStatus(currentDay, employment),
          };
        }),
      };
      dataItem.assignedCount = assignedCount;
      return dataItem;
    });

    const filteredAssignments = this.mergeWorkerAssignments(assignments);

    return { assignments: filteredAssignments, count };
  };

  getAttendance = (workerId, shifts) => {
    const attendances = shifts.flatMap(shift => shift.attendances);
    return attendances.find(
      attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
    );
  };

  handleCopyShift = async selectedCopyDateStart => {
    const { shifts, timezone, employments } = this.state;
    const fromShifts = cloneDeep(shifts);
    const selectedDateStart = datetimeUtils.getIsoWeekStart(selectedCopyDateStart, timezone);

    this.updateSelectDateState(selectedDateStart, WEEKLY_DATE_RANGE);
    const targetShifts = await this.fetchShifts(selectedCopyDateStart);

    this.setState({ originalShifts: targetShifts });
    const draftTargetShifts = cloneDeep(targetShifts);

    const updates = draftTargetShifts
      .flatMap(shift => {
        const fromShift = fromShifts.find(
          item =>
            moment(shift.start_time)
              .tz(timezone)
              .format('ddd') ===
              moment(item.start_time)
                .tz(timezone)
                .format('ddd') &&
            item.schedule.id === shift.schedule.id &&
            // only allow copying to shifts starting from today and future
            moment(shift.start_time) > datetimeUtils.getDayStart(moment(), timezone),
        );
        return fromShift?.attendances
          .filter(attendance => {
            const targetShiftDay = datetimeUtils.getDayStart(shift.start_time, timezone);
            const targetAttendance = shift.attendances.find(item => item.partner_id === attendance.partner_id);
            const hasConfirmedTargetAttendance = targetAttendance?.confirmed === true;
            const workerEmployment = employments.find(employment => employment.partner.id === attendance.partner_id);
            const contractStatus = workerEmployment
              ? employmentUtils.getDerivedWorkingStatus(targetShiftDay, workerEmployment)
              : derivedWorkingStatus.CONTRACT_NOT_SIGNED;
            // Do not copy shift to confirmed target shift
            return (
              attendance.status === attendanceStatuses.ASSIGNED &&
              attendanceUtils.isValidWorkingDay(contractStatus) &&
              !hasConfirmedTargetAttendance
            );
          })
          .map(attendance => {
            return {
              employmentId:
                attendance.employmentId ||
                employments.find(employment => employment.partner.id === attendance.partner_id).id,
              targetShiftId: shift.id,
              date: shift.start_time,
              workerId: attendance.partner_id,
              source: COPY_SHIFTS,
            };
          });
      })
      .filter(update => update);

    this.handleAssignmentUpdate(updates);
  };

  getStatistics = shifts => {
    const { employments, timezone } = this.state;
    const dayStart = datetimeUtils.getDayStart(moment(), timezone);

    const attendances = shifts
      .filter(shift => moment(shift.start_time).isSameOrAfter(dayStart))
      .flatMap(shift =>
        shift.attendances.filter(attendance =>
          employments.find(employment => employment.partner.id === attendance.partner_id),
        ),
      );
    const absencesCount = attendances.reduce(
      (count, attendance) => (attendance.absence_reason || attendance.absence_detail ? count + 1 : count),
      0,
    );

    const unconfirmedAttendances = attendances.filter(
      attendance => attendance.status === attendanceStatuses.ASSIGNED && !attendance.confirmed,
    );
    const confirmedAttendances = attendances.filter(
      attendance => attendance.status === attendanceStatuses.ASSIGNED && attendance.confirmed,
    );
    const unconfirmedAssignmentCount = unconfirmedAttendances.length || 0;
    const confirmedAssignmentCount = confirmedAttendances.length || 0;
    const unconfirmedWorkerList = uniq(unconfirmedAttendances.map(attendance => attendance.partner_id));

    const unfilledShiftCount = shifts.filter(shift => {
      const assignedCount = shift.attendances.filter(att => att.status === attendanceStatuses.ASSIGNED).length;
      return moment(shift.start_time).isSameOrAfter(dayStart) && assignedCount < shift.staff_required;
    }).length;

    this.setState({
      confirmedAssignmentCount,
      unconfirmedAssignmentCount,
      unconfirmedWorkerList,
      absencesCount,
      unfilledShiftCount,
    });
  };

  // Fetch schedules for all locations and positions to split into has/no schedules
  fetchSchedules = async (clientId, weekStart, dataRangeType = null) => {
    this.setState({ locationLoading: true });
    const { selectedDateRange } = this.state;
    const dateRange = dataRangeType || selectedDateRange;
    const startDate = weekStart;
    const endDate = weekStart.clone().endOf(`${dateRange === WEEKLY_DATE_RANGE ? 'isoWeek' : 'month'}`);

    const allSchedules = await fetchAll(scheduleApi.fetchSchedules, {
      client: clientId,
      start_date_before: endDate.toISOString(),
      end_date_after: startDate.toISOString(),
    });

    this.setState({ allSchedules, locationLoading: false });
  };

  shouldNavigateAway = publishCount => {
    const { t } = this.props;
    if (publishCount > 0) {
      // eslint-disable-next-line no-alert
      const confirmNavigateAway = window.confirm(t('shiftsNavigateAwayWarning'));
      if (!confirmNavigateAway) {
        return false;
      }
    }
    return true;
  };

  fetchShiftsAndSchedules = async () => {
    const { selectedDateStart } = this.state;
    this.updateSelectDateState(selectedDateStart);
    const [shifts] = await Promise.all([
      this.fetchShifts(selectedDateStart),
      this.fetchSchedules(this.props.clientId, selectedDateStart),
    ]);
    this.getStatistics(shifts);
  };

  handleChangeDateRange = (publishCount, dateRangeType) => {
    const { selectedDateRange } = this.state;
    if (selectedDateRange !== dateRangeType) {
      const shouldNavigateAway = this.shouldNavigateAway(publishCount);
      if (shouldNavigateAway) {
        const { timezone } = this.state;
        const isWeekly = dateRangeType === WEEKLY_DATE_RANGE;
        const selectedDateStart = isWeekly
          ? datetimeUtils.getIsoWeekStart(moment().toISOString(), timezone)
          : datetimeUtils.getIsoMonthStart(moment().toISOString(), timezone);
        this.setState(
          { scheduleLoading: true, selectedDateRange: dateRangeType, selectedDateStart },
          this.fetchShiftsAndSchedules,
        );
      }
    }
  };

  render() {
    const {
      employments,
      shifts,
      originalShifts,
      scheduleLoading,
      assignmentsLoading,
      statisticsLoading,
      selectedDateStart,
      absencesCount,
      confirmedAssignmentCount,
      unconfirmedAssignmentCount,
      unconfirmedWorkerList,
      addScheduleModalVisible,
      unfilledShiftCount,
      locationId,
      positionId,
      timezone,
      locationLoading,
      positionLoading,
      allSchedules,
      autoAssignLoading,
      selectedDayDates,
      selectedDateRange,
    } = this.state;

    const { locations, positions, clientId, t } = this.props;

    const rate = (confirmedAssignmentCount / (confirmedAssignmentCount + unconfirmedAssignmentCount)) * 100;
    const confirmationRate = rate ? rate.toFixed(0) : 0;

    let assignmentData;
    let publishCount;
    if (employments.length > 0) {
      const { assignments, count } = this.generateAssignmentTableData();
      // eslint-disable-next-line no-console
      console.log('assignments', JSON.stringify(assignments));
      assignmentData = assignments;
      publishCount = count;
    }

    const schedules = uniqBy(
      shifts.map(shift => ({
        ...shift.schedule,
        isGig: shift.staff_request?.employment_type === employmentTypes.GIG.value,
      })),
      'id',
    );
    const locationIdsWithSchedule = uniq(allSchedules.map(schedule => schedule.location.id));
    const locationSchedules = allSchedules.filter(schedule => schedule.location === locationId);
    const positionIdsWithSchedule = uniq(locationSchedules.map(schedule => schedule.position));
    const orderedLocations = sortBy(locations, 'name');

    // Prompt warning on close and refresh window if there is new attendance to publish
    window.onbeforeunload = () => (publishCount > 0 ? '' : null);

    return (
      <div style={{ paddingBottom: 100 }}>
        <Row type="flex" gutter={4}>
          <Col>
            <Title level={2} style={{ paddingRight: 24, display: 'inline' }}>
              {t('shifts')}
            </Title>
          </Col>
          <Col span={6}>
            <Select
              loading={locationLoading}
              placeholder={t('selectLocation')}
              style={{ width: '100%' }}
              filterOption={false}
              onChange={locationIdValue =>
                this.shouldNavigateAway(publishCount) &&
                this.setState({
                  locationId: locationIdValue,
                  positionId: undefined,
                  timezone:
                    locations.find(location => location.id === locationIdValue)?.address?.area?.city?.timezone ||
                    DEFAULT_TIMEZONE,
                })
              }
              value={locationId}
            >
              <OptGroup label={t('hasSchedules').toUpperCase()}>
                {orderedLocations
                  .filter(({ id }) => locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label={t('noSchedules').toUpperCase()}>
                {orderedLocations
                  .filter(({ id }) => !locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Col>
          <Col span={6}>
            <Select
              loading={positionLoading}
              placeholder={t('selectPosition')}
              style={{ width: '100%' }}
              filterOption={false}
              onSelect={positionIdValue =>
                this.shouldNavigateAway(publishCount) &&
                this.setState({ positionId: positionIdValue }, () => this.handlePositionChange())
              }
              value={positionId}
            >
              <OptGroup label={t('hasSchedules').toUpperCase()}>
                {positions
                  .filter(({ id }) => positionIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label={t('noSchedules').toUpperCase()}>
                {positions
                  .filter(({ id }) => !positionIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 48 }} />
        {positions.length > 0 && locations.length > 0 && clientId && locationId && positionId && timezone ? (
          <>
            {/* SR Summary Card */}
            <div style={{ marginBottom: '48px' }}>
              <ShiftsSummaryCard
                employmentCount={employments.length}
                confirmationRate={confirmationRate}
                loading={statisticsLoading}
                unconfirmedWorkerCount={unconfirmedWorkerList.length}
                absencesCount={absencesCount}
                unfilledShiftCount={unfilledShiftCount}
                position={positions.find(position => position.id === positionId)}
                location={locations.find(location => location.id === locationId)}
                scheduleCount={schedules.length}
              />
            </div>
            <WeekMonthSelectGroup
              selectedDateStart={selectedDateStart}
              timezone={timezone}
              loading={scheduleLoading}
              onChange={value => this.handleDateSelect(value, publishCount)}
              disableFutureDates={false}
              selectedDateRange={selectedDateRange}
              handleMonthSelect={() => this.handleChangeDateRange(publishCount, MONTHLY_DATE_RANGE)}
              handleWeekSelect={() => this.handleChangeDateRange(publishCount, WEEKLY_DATE_RANGE)}
            />
            <Divider style={{ margin: '20px 0px 20px 0px' }} />
            {/* Week Selector and Action Buttons */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
              {/* Select value is always in ISO format */}
              {/* Convert it into a moment object when setting selectedWeekStart from the selected value */}
              <Button
                type="v2-primary"
                disabled={scheduleLoading}
                onClick={() => {
                  this.setState({ addScheduleModalVisible: true });
                }}
              >
                {`+ ${t('addSchedule')}`}
              </Button>
            </div>

            {/* Scheduling */}
            <ScheduleListView
              loading={scheduleLoading}
              schedules={schedules}
              shifts={shifts}
              dayDates={selectedDayDates}
              onScheduleUpdate={this.handleRefresh}
              timezone={timezone}
              selectedDateRange={selectedDateRange}
            />

            {/* Assignment */}
            <AssignmentListView
              employments={employments}
              schedules={schedules}
              shifts={shifts}
              originalShifts={originalShifts}
              dayDates={selectedDayDates}
              onRefresh={this.handleRefresh}
              onCopy={this.handleCopyShift}
              onUpdate={this.handleAssignmentUpdate}
              onAutoAssign={this.handleAutoAssign}
              loading={assignmentsLoading || scheduleLoading}
              assignmentData={assignmentData}
              publishCount={publishCount}
              timezone={timezone}
              clientId={clientId}
              autoAssignLoading={autoAssignLoading}
              isAutoAssignDisabled={schedules.length === 0 && shifts.length === 0}
              selectedDateRange={selectedDateRange}
            />
            {addScheduleModalVisible && (
              <AddScheduleFormModal
                visible={addScheduleModalVisible}
                onCancel={() => this.setState({ addScheduleModalVisible: false })}
                onUpdate={() => {
                  this.setState({ addScheduleModalVisible: false });
                  this.handleRefresh();
                }}
                position={positions.find(position => position.id === positionId)}
                location={locations.find(location => location.id === locationId)}
                timezone={timezone}
                clientId={clientId}
              />
            )}
          </>
        ) : (
          <Empty
            image={shiftsEmpty}
            imageStyle={{ height: 200, alignContent: 'center' }}
            description={
              <>
                <Row style={{ marginTop: '32px' }} justify="center">
                  <Col>
                    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('emptyScheduleTitle')}</Text>
                  </Col>
                </Row>
                <Row style={{ marginTop: '8px' }} justify="center">
                  <Col>
                    <Text>{t('emptyScheduleDescription')}</Text>
                  </Col>
                </Row>
              </>
            }
          />
        )}

        {timezone && assignmentData && (
          <StickyLegendBar
            employments={employments}
            shifts={shifts}
            originalShifts={originalShifts}
            dayDates={selectedDayDates}
            onRefresh={this.handleRefresh}
            onCopy={this.handleCopyShift}
            loading={assignmentsLoading}
            publishCount={publishCount}
            timezone={timezone}
            clientId={clientId}
            selectedDateRange={selectedDateRange}
            assignmentData={assignmentData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
  locations: state.user.locations,
  clientId: state.user.clientId,
});

export default withTranslation()(connect(mapStateToProps)(ShiftPage));
